import GithubProvider from "./core/GithubProvider";
import Footer from "./components/Footer/Footer";
import {AppWrapper, MainWrapper} from "./Main.style";
import Home from "./components/Home/Home";
import ParticleNetworkAnimation from './core/ParticlesBackground/ParticlesAnimator.js'
import {createRef, useEffect} from "react";
import ThemeSwitcherProvider from "./core/ThemeSwitcherProvider";

function Main() {
    const mainWrapperRef = createRef();

    useEffect(() => {
        if (mainWrapperRef && mainWrapperRef.current) {
            const pna = new ParticleNetworkAnimation();
            pna.init(mainWrapperRef.current)
        }
    }, [mainWrapperRef])

    return <ThemeSwitcherProvider>
        <MainWrapper ref={mainWrapperRef}>
            <GithubProvider>
                <AppWrapper>
                    <Home/>
                    <Footer/>
                </AppWrapper>
            </GithubProvider>
        </MainWrapper>
    </ThemeSwitcherProvider>
}

export default Main;
