import styled from "styled-components";

export const HomeWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const LeftSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 8rem;
  z-index: 2;
  pointer-events: none;
  min-height: calc(100vh - 5.5rem);

  img {
    max-width: 26rem;
    pointer-events: none;
    object-fit: contain;

    @media screen and (max-width: 1200px) {
      width: 80%;
      min-height: unset;
      height: 10rem;
      margin-top: 2rem;
    }
  }

  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
    margin-top: 4rem;
    max-width: 85%;
    color: var(--font-color);

    .our-work {
      position: relative;

      img {
        max-width: 12rem;
        width: calc(8rem + 2vw);
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(110%, 65%);
        z-index: 99;

        @media screen and (max-width: 1200px) {
          display: none;
        }
      }
    }

    .contact-us {
      &.email {
        pointer-events: auto;
        color: #00eeae;
        
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    flex: unset;
    padding: 0;
    min-height: unset;
  }

  @media screen and (max-width: 480px) {
    pointer-events: auto;
  }
`;

export const RightSection = styled.div`
  position: relative;
  flex: 2;
  padding: 0.5rem 2rem 0.5rem 3rem;
  border-left: 1px solid var(--border-color);
  background-color: var(--color-primary);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 9rem);
  
  .github-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 5rem;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 8rem;
    border: 1px dotted var(--border-color);
    transform: translate(-100%, 0);
  }

  @media screen and (max-width: 1200px) {
    flex: unset;
    margin-top: 2rem;
    padding: 0;
    width: 100%;
    min-height: 45vh;
  }
`;