import styled from "styled-components";

export const ReposListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .title {
      margin: 0.5rem;
      color: var(--font-color);
    }
    
    .github-icon {
      position: relative;
      transform: translate(0, 0);
      max-width: 2rem;
      display: none;
      
      @media screen and (max-width: 1200px) {
        display: block;
      }
    }
  }
  
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
    max-height: calc(100vh - 9rem);
    padding: 0.5rem 2rem 2rem 0.5rem;

    @media screen and (max-width: 1200px) {
      padding: 0.5rem 0.5rem 2rem 0.5rem;
      overflow: inherit;
      max-height: unset;
    }
  }

  .loader {
    margin-top: 2rem;
  }
`;

export const RepoCard = styled.div`
  border-radius: 0.4rem;
  padding: 1rem;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
  background-color: var(--color-primary); 
  color: var(--font-color);
  border: 1px solid var(--border-color);
  
  .title {
    margin: 0 0 0.5rem 0;
    
    a {
      text-decoration: none;
      color: var(--font-color);
    }
  }
  
  .description {
    font-size: 14px;
  }
  
  &:hover {
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
  }
`;