import {RepoStatsWrapper} from "./RepoStats.style";
import ReactTooltip from "react-tooltip";

const RepoStats = ({stars, watchers, languagesData}) => {
    return  <RepoStatsWrapper className="stats">
        <ReactTooltip className="languages-tooltip"/>
        <div className="pill">&#11088; {stars}</div>
        <div className="pill">&#128065; {watchers}</div>
        <div className="pill tooltip"
             data-tip={languagesData}
             data-html={true}>&#128200;</div>
    </RepoStatsWrapper>
}

export default  RepoStats;