import {useContext, useEffect, useState} from "react";
import {GithubContext} from "../../core/GithubProvider";
import {RepoCard, ReposListWrapper} from "./ReposList.style";
import RepoStats from "../RepoStats/RepoStats";
import {THEMES, ThemeSwitcherContext} from "../../core/ThemeSwitcherProvider";
import GithubIconBlack from "../../assets/media/github_icon_black.png";
import GithubIconWhite from "../../assets/media/github_icon_white.png";
import HeartLoader from "../reusable/HeartLoader/HeartLoader";

const ReposList = () => {
    const githubContext = useContext(GithubContext);
    const themeContext = useContext(ThemeSwitcherContext);
    const githubSrc = themeContext.theme === THEMES.light ? GithubIconBlack : GithubIconWhite;
    const loaderColor = themeContext.theme === THEMES.light ? 'red' : '#fff';
    const [repos, setRepos] = useState([]);

    useEffect(() => {
        const composeReposData = async () => {
            let formattedRepo = await Promise.all(githubContext.repositories
                .sort((a, b) => (b.stargazers_count > a.stargazers_count) ? 1 : -1)
                .map(async repo => {
                    return {
                        id: repo.id,
                        name: repo.name,
                        description: repo.description,
                        html_url: repo.html_url,
                        stargazers_count: repo.stargazers_count,
                        watchers: repo.watchers,
                        languagesStats: await githubContext.getLanguages(repo.languages_url)
                    }
                }))

            setRepos(formattedRepo)
        }

        if (githubContext.repositories) {
            composeReposData();
        }
    }, [githubContext])

    const composeLanguagesTooltipData = (value) => {
        let languages = ``;

        Object.keys(value.languages).forEach(lang => {
            const percentage = (value.languages[lang] / value.totalCount) * 100;
            languages += `<span>${lang}: ${percentage.toFixed(2)}%</span><br/>`
        })

        return languages

    }

    return <ReposListWrapper>
        <div className="header">
            <img className="github-icon" src={githubSrc} alt="Github Icon"/>
            <h4 className="title">Our activity</h4>
        </div>
        {
            repos && repos.length > 0 ? <ul>
                    {
                        repos.map(repo => <RepoCard key={repo.id}>
                            <h4 className="title">
                                <a href={repo.html_url} alt={repo.name} target="_blank" rel="noreferrer">{repo.name}</a>
                            </h4>
                            <span className="description">{repo.description}</span>
                            <RepoStats
                                stars={repo.stargazers_count}
                                watchers={repo.watchers}
                                languagesData={composeLanguagesTooltipData(repo.languagesStats)}
                            />
                        </RepoCard>)
                    }
                </ul>
                : <HeartLoader
                    className="loader"
                    color={loaderColor}
                />
        }
    </ReposListWrapper>
}

export default ReposList;