import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
import ResetCSS from "./assets/css/globalStyle";

ReactDOM.render(
    <React.StrictMode>
        <ResetCSS/>
        <Main/>
    </React.StrictMode>,
    document.getElementById('root')
);