import ParticleNetwork from "./ParticleNetwork";

class ParticleNetworkAnimator {
    constructor() {}

    init(element) {
        this.container = element;
        this.canvas = document.createElement('canvas');
        this.sizeCanvas();
        this.container.prepend(this.canvas);
        this.ctx = this.canvas.getContext('2d');
        this.particleNetwork = new ParticleNetwork(this);
        this.bindUiActions();

        return this;
    }

    bindUiActions() {
        window.addEventListener('resize', function () {
            // this.sizeContainer();
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.sizeCanvas();
            this.particleNetwork.createParticles();
        }.bind(this), false);
    }

    sizeCanvas() {
        this.canvas.width = this.container.offsetWidth;
        this.canvas.height = this.container.offsetHeight;
    }
}

export default ParticleNetworkAnimator;