import styled, {keyframes} from 'styled-components';

const glow1move = keyframes`
  from {
    transform: translate(-100%, 100%);
  }
  to {
    transform: translate(100%, -100%);
  }
`

const glow2move = keyframes`
  from {
    transform: translate(-100%, 0%);
  }
  to {
    transform: translate(100%, 100%);
  }
`;

const glow3move = keyframes`
  from {
    transform: translate(100%, 100%);
  }
  to {
    transform: translate(0%, -100%);
  }
`;

export const MainWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: var(--color-primary);
  
  canvas {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .glow {
    z-index: -1;
    position: fixed;
    top: 50%;
    left: 50%;
    background-image: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.025), transparent);
  }

  .glow-1 {
    width: 150vw;
    height: 150vh;
    margin-top: -75vh;
    margin-left: -75vw;
    animation: ${glow1move} 25s linear infinite both;
  }

  .glow-2 {
    width: 100vw;
    height: 100vh;
    margin-top: -50vh;
    margin-left: -50vw;
    animation: ${glow2move} 25s linear 25s / 3 infinite both;
  }

  .glow-3 {
    width: 120vw;
    height: 120vh;
    margin-top: -60vh;
    margin-left: -60vw;
    animation: ${glow3move} 25s linear 25s / 3 * 2 infinite both;
  }
`;

export const AppWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;