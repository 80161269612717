import {FooterWrapper} from "./Footer.style";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";

const Footer = () => {
    return <FooterWrapper>
        <span className="made-by">Made with love &#10084;&#65039; by Buidler-labs Team</span>
        <ThemeSwitcher/>
    </FooterWrapper>
}

export default Footer;