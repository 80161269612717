import styled from "styled-components";

export const RepoStatsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 1rem;
  gap: 0.5rem;

  .pill {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-color);
    padding: 0 0.5rem;
    border-radius: 5px;
    line-height: 1.8;

    &.tooltip {
      cursor: pointer;
    }
  }
  
  .languages-tooltip {
    border: 1px solid var(--border-color)
  }
`;