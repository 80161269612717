import {createContext, useEffect, useState} from "react";

export const ThemeSwitcherContext = createContext();

export const THEMES = {
    light: 'light',
    dark: 'dark'
}

const ThemeSwitcherProvider = ({children}) => {
    const [theme, setTheme] = useState(THEMES.light);

    const handleChangeTheme = (theme) => {
        localStorage.setItem('theme', theme)
        document.documentElement.className = theme
        setTheme(theme)
    }

    const contextValue = {
        theme,
        changeTheme: handleChangeTheme
    }

    useEffect(() => {
        const previousTheme = localStorage.getItem('theme')

        if(previousTheme === THEMES.light || previousTheme === THEMES.dark){
            handleChangeTheme(previousTheme)
        }else{
            handleChangeTheme(THEMES.light)
        }
    })

    return <ThemeSwitcherContext.Provider value={contextValue}>
        {children}
    </ThemeSwitcherContext.Provider>
}

export default ThemeSwitcherProvider;