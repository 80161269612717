import ReposList from "../ReposList/ReposList";
import {HomeWrapper, LeftSection, RightSection} from "./Home.style";
import {useContext} from "react";
import {THEMES, ThemeSwitcherContext} from "../../core/ThemeSwitcherProvider";
import BuidlerlabsLogo from '../../assets/media/buidlerlabs_logo.png';
import BuidlerlabsLogoWhite from '../../assets/media/buidlerlabs_logo_white.png';
import RightArrow from '../../assets/media/right_arrow.png';
import RightArrowWhite from '../../assets/media/right_arrow_white.png';
import GithubIconBlack from '../../assets/media/github_icon_black.png';
import GithubIconWhite from '../../assets/media/github_icon_white.png';

const CONTACT_EMAIL = process.env.REACT_APP_CONTACT_EMAIL || 'contact@buidlerlabs.com';

const Home = () => {
    const themeContext = useContext(ThemeSwitcherContext);
    const logoSrc = themeContext.theme === THEMES.light ? BuidlerlabsLogo : BuidlerlabsLogoWhite;
    const rightArrowSrc = themeContext.theme === THEMES.light ? RightArrow : RightArrowWhite;
    const githubSrc = themeContext.theme === THEMES.light ? GithubIconBlack : GithubIconWhite;

    return <HomeWrapper>
        <LeftSection>
            <img src={logoSrc} alt="Buidler Labs logo"/>
            <div className="about">
                <span>
                    Hey there! &#128075;
                    We're Buidler Labs, a team of crypto enthusiasts aiming to further push the blockchain dream of having a highly-available, highly-redundant, decentralized, censor-resistant and open web.
                </span>
                <span className="our-work">
                    Here are a couple of things that are in the works:
                    <img src={rightArrowSrc}/>
                </span>
                    <span className="contact-us">
                        Contact us:&nbsp;
                        <span
                            className="contact-us email"
                            onClick={() => window.location = `mailto:${CONTACT_EMAIL}`}
                        >{CONTACT_EMAIL}</span>
                </span>
            </div>
        </LeftSection>
        <RightSection>
            <img className="github-icon" src={githubSrc} alt="Github Icon"/>
            <ReposList/>
        </RightSection>
    </HomeWrapper>
}

export default Home;