import {SwitcherWrapper} from "./ThemeSwitcher.style";
import {useContext} from "react";
import {THEMES, ThemeSwitcherContext} from "../../core/ThemeSwitcherProvider";
import LightIcon from '../../assets/media/sunny.png';
import DarkIcon from '../../assets/media/night.png';

const ThemeSwitcher = () => {
    const {theme, changeTheme} = useContext(ThemeSwitcherContext);

    const isChecked = (_theme) => {
        return _theme === THEMES.light ? true : false;
    }

    const handleOnChange = (e) => {
        changeTheme(e.target.checked ? THEMES.light : THEMES.dark)
    }

    return <SwitcherWrapper lightIcon={LightIcon} darkIcon={DarkIcon}>
        <label id="switch" className="switch">
            <input type="checkbox"
                   checked={isChecked(theme)}
                   onChange={handleOnChange}
                   id="slider"
            />
            <span className="slider round"></span>
        </label>
    </SwitcherWrapper>
}

export default ThemeSwitcher;
