import {createContext, useEffect, useState} from "react";

export const GithubContext = createContext();

const GithubProvider = ({children}) => {
    const [state, setState] = useState({});

    const getRepositories = async (username) => {
        const response = await fetch(`https://api.github.com/users/${username}/repos`)
        return await response.json();
    }

    const getLanguages = async (repoLangUrl) => {
        const response = await fetch(repoLangUrl);
        const languages = await response.json();
        const totalCount = Object.values(languages).reduce((a, b) => a + b);

        return {
            languages,
            totalCount
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const username = process.env.REACT_APP_GITHUB_USER || 'buidler-labs'
            const repos = await getRepositories(username);

            setState(prevState => ({
                ...prevState,
                repositories: repos
            }))
        }

        fetchData();
    }, [])

    const value = {
        ...state,
        getLanguages
    }

    return <GithubContext.Provider value={value}>
        {children}
    </GithubContext.Provider>
}

export default GithubProvider;