import Particle from "./Particle";

class ParticleNetwork {
    constructor(parent) {
        this.options = {
            velocity: 1, // the higher the faster
            density: 15000, // the lower the denser
            netLineDistance: 200,
            netLineColor: '#929292',
            particleColors: ['#aaa'], // ['#6D4E5C', '#aaa', '#FFC458' ]
            maxParticles: 200
        };
        this.canvas = parent.canvas;
        this.ctx = parent.ctx;

        this.init();
    }

    init() {
        this.createParticles(true);

        // Update canvas
        this.animationFrame = requestAnimationFrame(this.update.bind(this));

        this.bindUiActions();
    }

    createParticles(isInitial) {
        // Initialise / reset particles
        var me = this;
        this.particles = [];
        var quantity = this.canvas.width * this.canvas.height / this.options.density;

        if (isInitial) {
            var counter = 0;
            clearInterval(this.createIntervalId);
            this.createIntervalId = setInterval(function () {
                if (counter < quantity - 1) {
                    // Create particle object
                    this.particles.push(new Particle(this));
                } else {
                    clearInterval(me.createIntervalId);
                }
                counter++;
            }.bind(this), 250);
        } else {
            // Create particle objects
            for (var i = 0; i < quantity; i++) {
                this.particles.push(new Particle(this));
            }
        }
    }

    createInteractionParticle() {
        // Add interaction particle
        this.interactionParticle = new Particle(this);
        this.interactionParticle.velocity = {
            x: 0,
            y: 0
        };
        this.particles.push(this.interactionParticle);
        return this.interactionParticle;
    }

    removeInteractionParticle() {
        // Find it
        var index = this.particles.indexOf(this.interactionParticle);
        if (index > -1) {
            // Remove it
            this.interactionParticle = undefined;
            this.particles.splice(index, 1);
        }
    }

    update() {
        if (this.canvas) {

            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.ctx.globalAlpha = 1;

            // Draw connections
            for (var i = 0; i < this.particles.length; i++) {
                for (var j = this.particles.length - 1; j > i; j--) {
                    var distance, p1 = this.particles[i], p2 = this.particles[j];

                    // check very simply if the two points are even a candidate for further measurements
                    distance = Math.min(Math.abs(p1.x - p2.x), Math.abs(p1.y - p2.y));
                    if (distance > this.options.netLineDistance) {
                        continue;
                    }

                    // the two points seem close enough, now let's measure precisely
                    distance = Math.sqrt(
                        Math.pow(p1.x - p2.x, 2) +
                        Math.pow(p1.y - p2.y, 2)
                    );
                    if (distance > this.options.netLineDistance) {
                        continue;
                    }

                    this.ctx.beginPath();
                    this.ctx.strokeStyle = this.options.netLineColor;
                    this.ctx.globalAlpha = (this.options.netLineDistance - distance) / this.options.netLineDistance * p1.opacity * p2.opacity;
                    this.ctx.lineWidth = 0.7;
                    this.ctx.moveTo(p1.x, p1.y);
                    this.ctx.lineTo(p2.x, p2.y);
                    this.ctx.stroke();
                }
            }

            // Draw particles
            for (var i = 0; i < this.particles.length; i++) {
                this.particles[i].update();
                this.particles[i].draw();
            }

            if (this.options.velocity !== 0) {
                this.animationFrame = requestAnimationFrame(this.update.bind(this));
            }

        } else {
            cancelAnimationFrame(this.animationFrame);
        }
    }

    bindUiActions() {
        // Mouse / touch event handling
        this.spawnQuantity = 3;
        this.mouseIsDown = false;
        this.touchIsMoving = false;

        this.onMouseMove = function (e) {
            if (!this.interactionParticle) {
                this.createInteractionParticle();
            }
            this.interactionParticle.x = e.offsetX;
            this.interactionParticle.y = e.offsetY;
        }.bind(this);

        this.onTouchMove = function (e) {
            e.preventDefault();
            this.touchIsMoving = true;
            if (!this.interactionParticle) {
                this.createInteractionParticle();
            }
            this.interactionParticle.x = e.changedTouches[0].clientX;
            this.interactionParticle.y = e.changedTouches[0].clientY;
        }.bind(this);

        this.onMouseDown = function (e) {
            this.mouseIsDown = true;
            var counter = 0;
            var quantity = this.spawnQuantity;
            var intervalId = setInterval(function () {
                if (this.mouseIsDown) {
                    if(this.particles.length < this.options.maxParticles) {
                        if (counter === 1) {
                            quantity = 1;
                        }

                        for (var i = 0; i < quantity; i++) {
                            if (this.interactionParticle) {
                                this.particles.push(new Particle(this, this.interactionParticle.x, this.interactionParticle.y));
                            }
                        }
                    }
                } else {
                    clearInterval(intervalId);
                }
                counter++;
            }.bind(this), 50);
        }.bind(this);

        this.onTouchStart = function (e) {
            e.preventDefault();
            setTimeout(function () {
                if (!this.touchIsMoving) {
                    for (var i = 0; i < this.spawnQuantity; i++) {
                        this.particles.push(new Particle(this, e.changedTouches[0].clientX, e.changedTouches[0].clientY));
                    }
                }
            }.bind(this), 200);
        }.bind(this);

        this.onMouseUp = function (e) {
            this.mouseIsDown = false;
        }.bind(this);

        this.onMouseOut = function (e) {
            this.removeInteractionParticle();
        }.bind(this);

        this.onTouchEnd = function (e) {
            e.preventDefault();
            this.touchIsMoving = false;
            this.removeInteractionParticle();
        }.bind(this);

        this.canvas.addEventListener('mousemove', this.onMouseMove);
        this.canvas.addEventListener('touchmove', this.onTouchMove);
        this.canvas.addEventListener('mousedown', this.onMouseDown);
        this.canvas.addEventListener('touchstart', this.onTouchStart);
        this.canvas.addEventListener('mouseup', this.onMouseUp);
        this.canvas.addEventListener('mouseout', this.onMouseOut);
        this.canvas.addEventListener('touchend', this.onTouchEnd);
    }

    unbindUiActions() {
        if (this.canvas) {
            this.canvas.removeEventListener('mousemove', this.onMouseMove);
            this.canvas.removeEventListener('touchmove', this.onTouchMove);
            this.canvas.removeEventListener('mousedown', this.onMouseDown);
            this.canvas.removeEventListener('touchstart', this.onTouchStart);
            this.canvas.removeEventListener('mouseup', this.onMouseUp);
            this.canvas.removeEventListener('mouseout', this.onMouseOut);
            this.canvas.removeEventListener('touchend', this.onTouchEnd);
        }
    }
}

export default ParticleNetwork;