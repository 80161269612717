import styled from "styled-components";

export const FooterWrapper = styled.div`
  padding: 2rem;
  border-top: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--font-color);
  
  @media screen and (max-width: 480px) {
    justify-content: space-between;
    
    .made-by {
      font-size: 12px;
    }
  }
`;