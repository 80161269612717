import {createGlobalStyle} from 'styled-components';

const ResetCSS = createGlobalStyle`
  .light {
    --color-primary: #fff;
    --border-color: #dcdcdc;
    --font-color: #000000;
    --scrollbar-track-color: #f1f1f1;
    --scroll-handle-color: #888;
  }

  .dark {
    --color-primary: #252525;
    --border-color: #424242;
    --font-color: #ffffff;
    --scrollbar-track-color: #5e5e5e;
    --scroll-handle-color: #4b4b4b;
  }

  ::selection {
    background: #333333;
    color: #ffffff;
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *:focus {
    outline: none;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
    font-family: 'Lato', sans-serif;
  }
  
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--scroll-handle-color);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
export default ResetCSS;